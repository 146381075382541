import { defineStore } from "pinia"
import { useTokenStore } from "./token"
import { filter, findIndex } from "lodash"
import axios from "axios"
import jwt from "@/utils/jwt"
import { toastController } from "@ionic/core"
import { unknown } from "zod"

const root = "account"

interface taskType {
  title: string
  completion: boolean
  type?: "lock"
  link?: string
}
interface accountListType {
  email: string
}
export const useAccountStore = defineStore("account", {
  state: () => ({
    account_id: "",
    tour_completion: false,
    onboarding_last_page: "",
    onboarding_completion: false,
    email: "",
    tasks: [] as Array<taskType>,
    retrieved: false,
    status: "",
    totalTaskCompleted: 0,
    type: "professional",
    listOfAccounts: [] as Array<accountListType>,
    hide_migration_banner: true,
  }),
  getters: {
    taskCompletion: ({ totalTaskCompleted, tasks }: { totalTaskCompleted: number; tasks: Array<taskType> }) => parseFloat(((totalTaskCompleted / tasks.length || 1) * 100).toFixed(2)),
  },
  actions: {
    /**
     * Removes the last completed page
     * from account store.
     */
    removeLastPage() {
      this.onboarding_last_page = ""
    },

    /**
     *
     * @param title  - title of the task saved in store. Used in determining index
     * @param nbf  - used for JWT
     *
     * Updates the status of of a task in tasks array to true, and saves
     * progress in db by calling save API
     */
    async updateTaskCompletion(title: string, status: boolean, nbf: number) {
      try {
        const index = findIndex(this.tasks, ["title", title])
        this.tasks[index].completion = status
        const header = useTokenStore().authorizationHeader
        const tokenizedPayload = jwt.generateToken(
          {
            tasks: this.tasks,
          },
          nbf
        )
        await axios.post(`${process.env.VUE_APP_WB_API}/${root}/me`, tokenizedPayload, header)
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     *
     * @param nbf  = time passed in generating jwt
     *
     * Updates the last page taken during onboarding
     */
    async updateLastPage(nbf: any) {
      try {
        const header = useTokenStore().authorizationHeader
        const tokenizedPayload = jwt.generateToken(
          {
            onboarding_last_page: this.onboarding_last_page,
            onboarding_completion: this.onboarding_completion,
          },
          nbf
        )
        await axios.post(`${process.env.VUE_APP_WB_API}/${root}/me`, tokenizedPayload, header)
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     *
     * @param page  = boolean, used as a determinant if onboarding steps are done
     * @param nbf = time passed in generating jwt
     *
     * Updates onboarding status if done or in progress
     */
    async updateOnboardingCompletion(page: boolean, nbf: any) {
      try {
        this.onboarding_completion = page
        const header = useTokenStore().authorizationHeader
        const tokenizedPayload = jwt.generateToken({ onboarding_completion: this.onboarding_completion }, nbf)
        await axios.post(`${process.env.VUE_APP_WB_API}/${root}/me`, tokenizedPayload, header)
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    async updateAny(nbf: any) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const header = useTokenStore().authorizationHeader
          const body = {
            tour_completion: this.tour_completion,
            onboarding_last_page: this.onboarding_last_page,
            onboarding_completion: this.onboarding_completion,
            email: this.email,
            tasks: this.tasks,
            retrieved: this.retrieved,
            status: this.status,
            totalTaskCompleted: this.totalTaskCompleted,
            type: this.type,
            hide_migration_banner: this.hide_migration_banner,
          }
          const tokenizedPayload = jwt.generateToken(body, nbf)
          await axios.post(`${process.env.VUE_APP_WB_API}/${root}/me`, tokenizedPayload, header)
          resolve(true)
        } catch (err: any) {
          const toast = await toastController.create({
            message: err.response?.data?.message || "Something went wrong",
            duration: 3000,
            position: "top",
            color: "danger",
          })
          await toast.present()
          reject()
        }
      })
    },

    /**
     * Retrieves customer account information and saves it to store
     */
    async retrieveCustomerAccount() {
      try {
        this.retrieved = false

        const config = useTokenStore().authorizationHeader
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/me`, config)
        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          const data = JSON.parse((parsed.payloadObj as any).sub)
          const isRetrieved = {
            retrieved: true,
          }
          this.$state = {
            ...isRetrieved,
            ...data,
          }
        }
        this.settingTask()
        if (!this.hide_migration_banner) this.migrateChecker()
      } catch (err: any) {
        if (err.response?.status === 401) {
          const toast = await toastController.create({
            message: "Your session expired.  Please enter your login details to proceed",
            duration: 3000,
            position: "top",
            color: "danger",
          })
          await toast.present()
          localStorage.removeItem("WBSAASAPP_TK")
          window.location.href = "/login"
        } else {
          const toast = await toastController.create({
            message: err.response?.data?.message || "Something went wrong",
            duration: 3000,
            position: "top",
            color: "danger",
          })
          await toast.present()
        }
      }
    },

    /**
     *
     * @param reason - User stated reason for deletion
     * @param nbf - number used for jwt
     *
     * Deletes the account and dissociates it from the company
     */
    async deleteCompanyUserAccount(reason: string, nbf: number) {
      try {
        const header = JSON.parse(JSON.stringify(useTokenStore().authorizationHeader))
        const tokenizedPayload = jwt.generateToken({ reason_of_deletion: reason }, nbf)
        header.data = tokenizedPayload
        await axios.delete(`${process.env.VUE_APP_WB_API}/${root}/me`, header)
        delete header.data
        return true
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
        return false
      }
    },

    async deactivateCompanyUserAccount(nbf: number) {
      try {
        this.$state.status = "deactivate"
        this.updateAny(nbf)
        return true
      } catch (error) {
        return false
      }
    },

    /**
     * setting active task
     */
    settingTask() {
      if (!this.tasks.length) {
        this.tasks = [
          {
            title: "Initial Workbean Page Setup",
            completion: this.onboarding_completion,
          },
          {
            title: "Basic Company Profile Setup",
            completion: false,
            link: "/workbean-page",
          },
          {
            title: "Connect Your Social Media Account",
            completion: false,
            link: "/workbean-page/connect",
          },
          {
            title: "Publish Workbean Page",
            completion: false,
          },
          {
            title: "Upload Employee Database",
            completion: false,
            type: "lock",
          },
          {
            title: "Launch Employee Experience Reviews",
            completion: false,
            type: "lock",
          },
          {
            title: "Launch Thought Leadership Posts",
            completion: false,
            type: "lock",
          },
        ]
      }
      const completed = filter(this.tasks, ({ completion }: { completion: boolean }) => completion)
      this.totalTaskCompleted = completed.length
    },
    /**
     * linkage account
     * @param params- request body
     * @param nbf - date in number
     */
    async linkAccount(params: object, nbf: number) {
      try {
        const header = useTokenStore().authorizationHeader
        const tokenizedPayload = jwt.generateToken(params, nbf)
        const response = await axios.post(`${process.env.VUE_APP_WB_API}/${root}/link`, tokenizedPayload, header)
        const message = response.data.to_generate ? "Check your email for verification" : "Successfully link"
        const toast = await toastController.create({
          message: message,
          duration: 3000,
          position: "top",
          color: "success",
        })
        await toast.present()
        return true
      } catch (err: any) {
        const toast = await toastController.create({
          message: err?.response?.data?.exception || err?.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
        return false
      }
    },
    async getLinkedAccount() {
      try {
        this.retrieved = false

        const config = useTokenStore().authorizationHeader
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/link`, config)
        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          const data = JSON.parse((parsed.payloadObj as any).sub)
          this.listOfAccounts = data
        }
      } catch (err: any) {
        if (err.response?.status === 401) {
          localStorage.removeItem("WBSAAS_TK")
          window.location.href = "/"
        } else {
          const toast = await toastController.create({
            message: err.response?.data?.message || "Something went wrong",
            duration: 3000,
            position: "top",
            color: "danger",
          })
          await toast.present()
        }
      }
    },
    /**
     * migrate data from V1 to current state
     */
    async migrateAccount() {
      try {
        const header = useTokenStore().authorizationHeader
        await axios.post(`${process.env.VUE_APP_WB_API}/${root}/migrate-account`, null, header)
        return true
      } catch (err: any) {
        return false
      }
    },

    /**
     * check email if migratable
     */
    async migrateChecker() {
      try {
        const header = useTokenStore().authorizationHeader
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/migrate-checker`, header)
        if (!response.data?.migratable) this.hide_migration_banner = true
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },
  },
})
