import { defineStore } from "pinia"
import { useTokenStore } from "@/store/token"
import { lightFormat } from "date-fns"
import axios from "axios"
import jwt from "@/utils/jwt"
import router from "@/router/index"
import { toastController } from "@ionic/vue"

const root = "authenticate"
const header = {
  headers: {
    "Content-Type": "text/html; charset=utf-8",
  },
}

export const useAuthenticationStore = defineStore("authentication", {
  state: () => ({
    loginDetails: {
      email: "",
      password: "",
      google_token: "",
      workbean_token: "",
      type: "professional",
      remembered: false,
    },
    registrationDetails: {
      email: "",
      password: "",
      as: "professional",
    },
    verificationCode: {
      email: "",
      otp: "******",
      as: "professional",
    },
    resetPassword: {
      email: "",
      type: "professional",
      code: "",
      password: "",
    },
    terms: false,
    verified: false,
    loading: true,
    authenticationWall: false,
  }),
  getters: {
    token: () => localStorage.getItem("WBSAASAPP_TK"),
  },
  actions: {
    /**
     *
     * @param newValue - object containing loginDetails
     * reactively updates values of loginDetails
     */
    setLoginDetails(newValue: { email: string; password: string; google_token: string; workbean_token: string }) {
      this.loginDetails = { ...this.loginDetails, ...newValue }
    },
    /**
     *
     * @param newValue - object containing registrationDetails
     * reactively updates values of registrationDetails
     */
    setRegistrationDetails(newValue: any) {
      this.registrationDetails = { ...this.registrationDetails, ...newValue }
    },
    /**
     *
     * @param newValue - object containing verificationCode
     * reactively updates values of verificationCode
     */
    setVerificationCode(newValue: any) {
      this.verificationCode = { ...this.verificationCode, ...newValue }
    },
    /**
     *
     * @param newValue - object containing verificationCode
     * reactively updates values of verificationCode
     */
    setResetPassword(newValue: { email: string; type: string; code: string; password: string }) {
      this.resetPassword = { ...this.resetPassword, ...newValue }
    },

    // api calls
    /**
     * login() calls for login api, passing the tokenized payload
     * from loginDetails
     *
     * displays toast notification containing server response
     */
    async login(nbf: number, redirect?: string, gtm?: any, email?: string) {
      try {
        const tokenizedPayload = jwt.generateToken(this.loginDetails, nbf)
        const response = await axios.post(`${process.env.VUE_APP_WB_API}/${root}/login`, tokenizedPayload, header)

        if (response.data.message) {
          if (gtm?.enabled()) {
            gtm.trackEvent({
              event: "sign_up",
              label: `${this.loginDetails.type} signup with google`,
              value: `${email} - ${this.loginDetails.type}`,
            })
          }
          this.login(nbf, "/details")
        } else {
          localStorage.removeItem("employee-email-active")
          useTokenStore().setToken(response.data)
          const toast = await toastController.create({
            message: "Logging in...",
            duration: 3000,
            position: "top",
            color: "success",
          })
          await toast.present()
          setTimeout(() => {
            const nextRoute = localStorage.getItem("saved-route")
            if (redirect) window.location.href = redirect
            else if (localStorage.getItem("saved-route")) {
              localStorage.removeItem("saved-route")
              window.location.href = nextRoute || "/workbean-world"
            } else window.location.href = "/workbean-world"
          }, 500)
        }
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     * Log outs the user by deleting the user token from localStorage
     */
    logout() {
      localStorage.removeItem("WBSAASAPP_TK")
      window.location.href = "/"
    },

    /**
     * register() calls for register api, passing the tokenized payload
     * from registrationDetails
     *
     * displays toast notification containing server response
     */
    async register(nbf: number, gtm?: any) {
      try {
        const tokenizedPayload = jwt.generateToken(this.registrationDetails, nbf)
        await axios.post(`${process.env.VUE_APP_WB_API}/${root}/register`, tokenizedPayload, header).then(async () => {
          if (gtm?.enabled()) {
            gtm.trackEvent({
              event: "sign_up",
              label: `${this.registrationDetails.as} signup with workbean`,
              value: `${this.registrationDetails.email} - ${this.registrationDetails.as}`,
            })
          }

          const toast = await toastController.create({
            message: "Setting up account...",
            duration: 3000,
            position: "top",
            color: "success",
          })
          await toast.present()
          this.loginDetails.email = this.registrationDetails.email
          this.loginDetails.password = this.registrationDetails.password
          this.login(nbf, "/verify")
        })
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     *
     * @param email - (string)
     * @param nbf - (number)
     *
     * request otp
     */
    async requestOTP(email: string, nbf: number) {
      try {
        const tokenizedPayload = jwt.generateToken({ email: email, as: this.verificationCode.as }, nbf)
        const response = await axios.post(`${process.env.VUE_APP_WB_API}/${root}/re-sent-one-time-pin`, tokenizedPayload, header)
        const toast = await toastController.create({
          message: "OTP sent. Please check your email.",
          duration: 3000,
          position: "top",
          color: "success",
        })
        await toast.present()
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     *
     * @param nbf - (number)
     * @param redirect - default true (boolean)
     *
     * verify with code
     */
    async verifyCode(nbf: number, redirect = true) {
      try {
        const tokenizedPayload = jwt.generateToken(this.verificationCode, nbf)
        const response = await axios.post(`${process.env.VUE_APP_WB_API}/${root}/verification`, tokenizedPayload, header)
        const toast = await toastController.create({
          message: "Account successfully verified",
          duration: 3000,
          position: "top",
          color: "success",
        })
        await toast.present()
        if (redirect) {
          setTimeout(() => {
            window.location.href = "/workbean-world"
          }, 3000)
        } else {
          if (response.data.success) return true
          else return false
        }
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
        return false
      }
    },

    /**
     *
     * @returns true if password request is successful and false if password request failed
     *
     * Sends a password request and sends a password reset code to the user
     */
    async requestResetPassword(nbf: number, email: string) {
      try {
        const tokenizedPayload = jwt.generateToken({ email: email, as: this.resetPassword.type }, nbf)
        const response = await axios.post(`${process.env.VUE_APP_WB_API}/${root}/request-reset-password`, tokenizedPayload, header)
        const toast = await toastController.create({
          message: "Six digit code sent to your email.",
          duration: 3000,
          position: "top",
          color: "success",
        })

        await toast.present()
        setTimeout(() => {
          router.push({ name: "Set Password", query: { email: email } })
        }, 1000)
        return true
      } catch (err: any) {
        const toast = await toastController.create({
          message: err?.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })

        await toast.present()
        return false
      }
    },

    /**
     *
     * @returns true if password reset is successful and false it fails.
     *
     * Calls for the reset password api
     */
    async resetUserPassword(nbf: number) {
      try {
        const params = JSON.parse(JSON.stringify(this.resetPassword))
        params.code = parseInt(params.code)
        const tokenizedPayload = jwt.generateToken(params, nbf)
        const response = await axios.post(`${process.env.VUE_APP_WB_API}/${root}/reset-password`, tokenizedPayload, header)
        const toast = await toastController.create({
          message: "Password updated successfully.",
          duration: 3000,
          position: "top",
          color: "success",
        })

        await toast.present()
        window.location.href = "/login"
        return true
      } catch (err: any) {
        const toast = await toastController.create({
          message: err?.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })

        await toast.present()
        return false
      }
    },
    /**
     * change password based on old password
     * @param params object of new password and old password
     * @param nbf - time in number
     * @param token - token (string)
     */
    async changePassword(
      params: {
        password: string
        old_password: string
      },
      nbf: number,
      token?: string
    ) {
      try {
        const config = useTokenStore().authorizationHeader
        if (token) {
          config.headers = {
            "Content-Type": "text/html; charset=utf-8",
            Authorization: `Bearer ${token}`,
          }
        }
        const tokenizedPayload = jwt.generateToken(params, nbf)
        await axios.post(`${process.env.VUE_APP_WB_API}/${root}/change-password`, tokenizedPayload, config as any)
        const toast = await toastController.create({
          message: "Password updated successfully.",
          duration: 3000,
          position: "top",
          color: "success",
        })
        await toast.present()
        return true
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
        return false
      }
    },

    /**
     *
     * @param params - (object)
     * @param nbf - (number)
     * @param status - default pending (string)
     *
     * @returns :response if successful else :false (object | boolean)
     */
    async verifyEmail(
      params: {
        email: string
        as: "employee" | "professional"
      },
      nbf: number,
      status = "pending"
    ) {
      try {
        const tokenizedPayload = jwt.generateToken(params, nbf)
        const response = await axios.post(`${process.env.VUE_APP_WB_API}/${root}/verify-email?status=${status}`, tokenizedPayload, header)
        if (response.data.success) {
          return response.data
        }
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
        return false
      }
    },
  },
})
