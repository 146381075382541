import { createRouter, createWebHistory } from "@ionic/vue-router"
import { RouteRecordRaw } from "vue-router"
import { IonNav } from "@ionic/vue"
// old links not included in consultancy release
import SignupPage from "@/views/Signup.vue"
import ResetPage from "@/views/ResetPage.vue"
import VerifyPage from "@/views/VerifyPage.vue"
import SetPasswordPage from "@/views/SetPassword.vue"
import DetailsPage from "@/views/DetailsPage.vue"
import JobBoardPage from "@/views/job-board/JobBoardPage.vue"
import LoginPage from "../views/LoginPage.vue"
import TalentAccount from "@/views/talent-account/TalentAccount.vue"
import AccountSecurity from "@/views/talent-account/AccountSecurity.vue"
import AccountConnections from "@/views/talent-account/AccountConnections.vue"
import DiscoverPage from "@/views/DiscoverPage.vue"
import EmployeeAmbassadorship from "@/views/post/AmbassadorshipPostPage.vue"
import WorkbeanWorld from "@/views/world/WorkbeanWorld.vue"
import ThoughtLeadershipPage from "@/views/post/LeadershipPostPage.vue"
// import talentProfile from "./talentProfile"
import survey from "./survey"
import EmployeeAmbassadorshipPage from "../views/question/EmployeeAmbassadorship.vue"
import ThoughtLeadershipQuestionPage from "../views/question/ThoughtLeadership.vue"
import PrivacyPolicy from "@/views/PolicyPage.vue"
import TermsAndCondition from "@/views/TermsPage.vue"
import LeadershipWallPage from "@/views/LeadershipPage.vue"

// consultancy pages with paying company page
import CompanyPage from "@/views/workbean-page/CompanyPage.vue"
import JobPage from "@/views/job-board/JobPage.vue"
import SolutionsPage from "../views/SolutionsPage.vue"
import ResourcesPage from "@/views/resources/ResourcesPage.vue"
import ResourcesHomepage from "@/views/resources/ResourcesHomepage.vue"
import AboutUs from "@/views/AboutPage.vue"
import Services from "@/views/ServicesPage.vue"
import Works from "@/views/WorksPage.vue"
import WorksPost from "@/views/WorkPostPage.vue"
import Contact from "@/views/ContactPage.vue"
import NotFound from "@/views/404Page.vue"

const routes = [
  // {
  //   path: "/",
  //   name: "SolutionsPage",
  //   component: SolutionsPage,
  //   meta: {
  //     active: "solutions",
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/about-us",
  //   name: "About Page",
  //   component: AboutUs,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/our-work",
  //   name: "Works Page",
  //   component: Works,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/our-work/:slug",
  //   name: "Works Post",
  //   component: WorksPost,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/services",
  //   name: "Services Page",
  //   component: Services,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/contact-us",
  //   name: "Contact Page",
  //   component: Contact,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/blogs/:type?",
  //   name: "Workbean Blog | Employer Branding Hub",
  //   component: ResourcesHomepage,
  //   meta: {
  //     active: "resources",
  //     requiresAuth: false,
  //     guestOnly: false,
  //     details: {
  //       title: "Workbean Blog | Employer Branding Hub",
  //       description: "Welcome to your Employer Branding Hub, Workbean's blog for sharing tips, tricks and learning nuggets on everything you need to attract the best people in the planet.",
  //       og: {
  //         title: "Workbean Blog | Employer Branding Hub",
  //         description: "Welcome to your Employer Branding Hub, Workbean's blog for sharing tips, tricks and learning nuggets on everything you need to attract the best people in the planet.",
  //         url: window.location.href,
  //         image: "https://assets.workbean.co/logo/v4/workbean-meta-banner.png",
  //         canonical: window.location.href,
  //       },
  //       twitter: {
  //         image: "https://assets.workbean.co/logo/v4/workbean-meta-banner.png",
  //         title: "Workbean Blog | Employer Branding Hub",
  //         description: "Welcome to your Employer Branding Hub, Workbean's blog for sharing tips, tricks and learning nuggets on everything you need to attract the best people in the planet.",
  //       },
  //     },
  //   },
  // },
  // {
  //   path: "/blogs/:slug",
  //   name: "Resources Page",
  //   component: ResourcesPage,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/discover-companies",
  //   name: "DiscoverPage",
  //   component: DiscoverPage,
  //   meta: {
  //     active: "discover",
  //     requiresAuth: false,
  //     guestOnly: false,
  //     details: {
  //       title: "Best Companies to Work for in the Philippines | Workbean",
  //       description: "Find the best companies in the Philippines for you. Research company culture and experience the best hiring platform.",
  //       og: {
  //         title: "Best Companies to Work for in the Philippines | Workbean",
  //         url: window.location.href,
  //         image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
  //         canonical: window.location.href,
  //         description: "Find the best companies in the Philippines for you. Research company culture and experience the best hiring platform.",
  //       },
  //       twitter: {
  //         image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
  //         title: "Best Companies to Work for in the Philippines | Workbean",
  //         description: "Find the best companies in the Philippines for you. Research company culture and experience the best hiring platform.",
  //       },
  //     },
  //   },
  // },
  // {
  //   path: "/login",
  //   name: "LoginPage",
  //   component: LoginPage,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/signup",
  //   name: "Signup Page",
  //   component: SignupPage,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //     details: {
  //       title: "Sign up for a Workbean Account",
  //       og: {
  //         title: "Sign up for a Workbean Account",
  //         url: window.location.href,
  //         image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
  //         canonical: window.location.href,
  //       },
  //       twitter: {
  //         image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
  //         title: "Sign up for a Workbean Account",
  //       },
  //     },
  //   },
  // },
  // {
  //   path: "/reset-password",
  //   name: "Reset Page",
  //   component: ResetPage,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: true,
  //     details: {
  //       title: "Reset Your Password - Workbean",
  //       og: {
  //         title: "Reset Your Password - Workbean",
  //         url: window.location.href,
  //         image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
  //         canonical: window.location.href,
  //       },
  //       twitter: {
  //         image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
  //         title: "Reset Your Password - Workbean",
  //       },
  //     },
  //   },
  // },
  // {
  //   path: "/set-password",
  //   name: "Set Password Page",
  //   component: SetPasswordPage,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/verify",
  //   name: "Verify Page",
  //   component: VerifyPage,
  //   meta: {
  //     requiresAuth: true,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/details",
  //   name: "Details Page",
  //   component: DetailsPage,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // job board
  // {
  //   path: "/job-board",
  //   name: "Job Board Page",
  //   component: JobBoardPage,
  //   meta: {
  //     active: "job",
  //     requiresAuth: false,
  //     guestOnly: false,
  //     details: {
  //       title: "Find jobs near you with great cultures | Workbean",
  //       description: "Find job vacancies and work from home jobs in the best hiring platform in the Philippines. Experience culture-first job searching.",
  //       og: {
  //         title: "Find jobs near you with great cultures | Workbean",
  //         url: window.location.href,
  //         image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
  //         canonical: window.location.href,
  //         description: "Find job vacancies and work from home jobs in the best hiring platform in the Philippines. Experience culture-first job searching.",
  //       },
  //       twitter: {
  //         image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
  //         title: "Find jobs near you with great cultures | Workbean",
  //         description: "Find job vacancies and work from home jobs in the best hiring platform in the Philippines. Experience culture-first job searching.",
  //       },
  //     },
  //   },
  // },
  // {
  //   path: "/job-board/:company/:slug/:job",
  //   name: "Job Page",
  //   component: JobPage,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  {
    path: "/company/:slug/:tab?",
    name: "Company Page",
    component: CompanyPage,
    meta: {
      requiresAuth: false,
      guestOnly: false,
    },
  },
  // {
  //   path: "/thought-leadership-wall",
  //   name: "Thought Leadership Wall Page",
  //   component: LeadershipWallPage,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/ambassadorship/:slug",
  //   name: "Employee Ambassadorship Page",
  //   component: EmployeeAmbassadorshipPage,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/thought-leadership/:slug",
  //   name: "Thought Leadership Page",
  //   component: ThoughtLeadershipQuestionPage,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/workbean-world",
  //   name: "WorkbeanWorld",
  //   component: WorkbeanWorld,
  //   meta: {
  //     active: "world",
  //     requiresAuth: true,
  //     guestOnly: false,
  //     details: {
  //       title: "Workbean World",
  //       og: {
  //         title: "Workbean World",
  //         url: window.location.href,
  //         image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
  //         canonical: window.location.href,
  //       },
  //       twitter: {
  //         image: "https://assets.workbean.co/logo/v3/w-rounded-colored.png",
  //         title: "Workbean World",
  //       },
  //     },
  //   },
  // },

  // {
  //   path: "/talent-account",
  //   name: "TalentAccount",
  //   component: TalentAccount,
  //   meta: {
  //     requiresAuth: true,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/account-login-and-security",
  //   name: "AccountSecurity",
  //   component: AccountSecurity,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/account-connections",
  //   name: "AccountConnections",
  //   component: AccountConnections,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/workbean-world/employee-ambassadorship/:slug",
  //   name: "EmployeeAmbassadorship",
  //   component: EmployeeAmbassadorship,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/workbean-world/thought-leadership/:tlid",
  //   name: "ThoughtLeadershipPage",
  //   component: ThoughtLeadershipPage,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //   },
  // },
  // {
  //   path: "/privacy-policy",
  //   name: "Privacy and Policy",
  //   component: PrivacyPolicy,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //     details: {
  //       title: "Workbean | Privacy Policy",
  //       og: {
  //         title: "Workbean | Privacy Policy by Workbean",
  //         url: window.location.href,
  //         image: "https://assets.workbean.co/logo/v4/workbean-meta-banner.png",
  //         canonical: window.location.href,
  //       },
  //       twitter: {
  //         image: "https://assets.workbean.co/logo/v4/workbean-meta-banner.png",
  //         title: "Workbean | Privacy Policy",
  //       },
  //     },
  //   },
  // },
  // {
  //   path: "/terms",
  //   name: "Terms and Condition",
  //   component: TermsAndCondition,
  //   meta: {
  //     requiresAuth: false,
  //     guestOnly: false,
  //     details: {
  //       title: "Workbean | Terms & Conditions",
  //       og: {
  //         title: "Workbean | Terms & Conditions",
  //         url: window.location.href,
  //         image: "https://assets.workbean.co/logo/v4/workbean-meta-banner.png",
  //         canonical: window.location.href,
  //       },
  //       twitter: {
  //         image: "https://assets.workbean.co/logo/v4/workbean-meta-banner.png",
  //         title: "Terms & Conditions by Workbean",
  //       },
  //     },
  //   },
  // },
  // ...survey,
  // ...talentProfile,
  {
    path: "/not-found",
    name: "Not Found",
    component: NotFound,
    meta: {
      requiresAuth: false,
      guestOnly: false,
      details: {
        title: "Error: Page Not Found",
        og: {
          title: "Error: Page Not Found",
          url: window.location.href,
          canonical: window.location.href,
          image: "https://assets.workbean.co/logo/v4/workbean-meta-banner.png",
        },
        twitter: {
          image: "https://assets.workbean.co/logo/v4/workbean-meta-banner.png",
          title: "Error: Page Not Found",
        },
      },
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/not-found",
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (!localStorage.getItem("WBSAASAPP_TK") && !to.fullPath.includes("/login") && !to.fullPath.includes("/signup") && !to.fullPath.includes("/request-reset-password")) {
    localStorage.setItem("saved-route", to.fullPath)
  }
  if (to.meta.requiresAuth && !localStorage.getItem("WBSAASAPP_TK")) {
    localStorage.setItem("URL", to.path)
    next({ name: "Login As", query: { redirect: to.fullPath } })
  } else if (to.meta.guestOnly && localStorage.getItem("WBSAASAPP_TK")) {
    if (to.fullPath.includes("/survey/employer-brand-personality") || to.fullPath.includes("/survey/employer-brand-matrix") || to.fullPath.includes("/survey/leadership-employer-brand-perception")) {
      localStorage.removeItem("WBSAASAPP_TK")
      next()
      window.location.reload()
    } else
      next({
        name: "WorkbeanWorld",
        query: { redirect: to.fullPath },
      })
  } else if ((to.fullPath.includes("/workbean-world/employee-ambassadorship/") || to.fullPath.includes("/workbean-world/thought-leadership/")) && from.path === "/" && !from.name) {
    localStorage.setItem("fromEmpty", "true")
    next()
  } else next()
})

export default router
