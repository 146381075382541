import { defineStore } from "pinia"
import { useTokenStore } from "./token"
import axios from "axios"
import { toastController } from "@ionic/vue"
import { values, max, map, filter, first, sortBy, take, takeRight, uniq, includes, reverse, each } from "lodash"

import jwt from "@/utils/jwt"

const root = "user"

export const useUserStore = defineStore("user", {
  state: () => ({
    customerDetails: {
      profile: {
        user_id: "",
        name: {
          first_name: "",
          last_name: "",
          full_name: "",
        },
        photo: "",
        employment: {
          role: "",
          department: "",
          years_exp: 0,
          status: "",
          industry: {
            id: 0,
          },
          work_setup: [],
          company: "",
          company_location: { city: "", country: "", full_address: "" },
        },
        phone_number: {
          default: "",
        },
        address: {
          country: "",
          city: "",
          full_address: "",
        },
        gender: "",
        birthdate: "",
        bio: "",
        displayed_email: "",
        uploaded_cv: "",
        socials: [],
        visibility: [],
        worksona: {
          explorer: 0,
          innocent: 0,
          innovator: 0,
          inventor: 0,
          jester: 0,
          philosopher: 0,
          realist: 0,
          supporter: 0,
          sweetheart: 0,
          warrior: 0,
          ruler: 0,
          rebel: 0,
          last_take: "",
        },
      },
      work_emails: [],
      experience: {
        education: [],
        work_history: [],
        projects: [],
        skills: [],
        certifications: [],
        interests: [],
      },
    },
    userTalentProfile: {
      user_id: "",
      name: {
        first_name: "",
        last_name: "",
        full_name: "",
      },
      photo: "",
      employment: {
        role: "",
        department: "",
        status: "",
        industry: {
          id: 0,
        },
        work_setup: [],
        company: "",
        company_location: { city: "", country: "", full_address: "" },
        years_exp: 0,
      },
      phone_number: {
        default: "",
      },
      address: {
        country: "",
        city: "",
        full_address: "",
      },
      gender: "",
      birthdate: "",
      bio: "",
      displayed_email: "",
      uploaded_cv: "",
      socials: [] as any[],
      visibility: [],
      worksona: {
        explorer: 0,
        innocent: 0,
        innovator: 0,
        inventor: 0,
        jester: 0,
        philosopher: 0,
        realist: 0,
        supporter: 0,
        sweetheart: 0,
        warrior: 0,
        ruler: 0,
        rebel: 0,
        last_take: "",
      },
    },
    worksona: "",
    worksonaDetails: {
      dominant: [] as string[],
      dormant: [] as string[],
      maxValue: 0,
    },
  }),
  actions: {
    /**
     *
     * @param newValue = any value initialized inside the state
     *
     * Sets any value inside the state
     */
    setCustomerDetails(newValue: any) {
      this.customerDetails = { ...this.customerDetails, ...newValue }
    },

    /**
     * Retrieves user's details.
     */
    async retrieveCustomerDetails() {
      try {
        const config = useTokenStore().authorizationHeader
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/me`, config)
        const parsed: any = jwt.parseToken(response.data)
        if (parsed) {
          const accountData = JSON.parse(parsed!.payloadObj!.sub)
          this.customerDetails.profile = {
            ...this.customerDetails.profile,
            ...accountData.profile,
          }
          this.customerDetails.work_emails = {
            ...this.customerDetails.work_emails,
            ...accountData.work_emails,
          }
          this.customerDetails.experience = {
            ...this.customerDetails.experience,
            ...accountData.experience,
          }
        }
      } catch (err: any) {
        if (err.response?.status !== 401 && err.response.data.exception !== "User data not found") {
          const toast = await toastController.create({
            message: err.response?.data?.message || "Something went wrong",
            duration: 3000,
            position: "top",
            color: "danger",
          })
          await toast.present()
        }
      }
    },

    /**
     *
     * @param nbf = time used to create jwt
     *
     * Saves user input sends it to backend.
     */
    saveCustomerDetails(nbf: number, payload?: object, successMessage?: string) {
      return new Promise((resolve, reject) => {
        const data = async () => {
          try {
            const config = useTokenStore().authorizationHeader
            let tokenizedPayload = jwt.generateToken(this.customerDetails, nbf)
            if (payload) {
              tokenizedPayload = jwt.generateToken(payload, nbf)
            }
            const response = await axios.post(`${process.env.VUE_APP_WB_API}/${root}/me`, tokenizedPayload, config)
            const toast = await toastController.create({
              message: successMessage || response.data.message,
              duration: 3000,
              position: "top",
              color: "success",
            })
            await toast.present()
            resolve(true)
            return true
          } catch (err: any) {
            const toast = await toastController.create({
              message: err.response?.data?.message || "Something went wrong",
              duration: 3000,
              position: "top",
              color: "danger",
            })
            await toast.present()
            reject()
          }
        }
        data()
      })
    },

    /**
     *
     * @param part - group name of profile attributes
     *
     * retrieve profile details by its part
     */
    async retrieveProfileDetails(part: "details" | "bio" | "employment" | "cv" | "socials" | "work_history" | "education" | "projects" | "skills" | "certifications" | "interests") {
      return new Promise((resolve, reject) => async () => {
        try {
          const config = useTokenStore().authorizationHeader
          const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/me`, config)
          const parsed: any = jwt.parseToken(response.data)
          if (parsed) {
            const accountData = JSON.parse(parsed!.payloadObj!.sub)
            let dataPart

            // for profile
            if (part === "details") {
              this.customerDetails.profile.user_id = accountData.profile.user_id
              this.customerDetails.work_emails = accountData.work_emails
              dataPart = {
                name: accountData.profile.name || "",
                photo: accountData.profile.photo || "",
                phone_number: accountData.profile.phone_number || "",
                address: accountData.profile.address || { country: "", city: "", full_address: "" },
                gender: accountData.profile.gender || "",
                birthdate: accountData.profile.birthdate || "",
                displayed_email: accountData.profile.displayed_email || "",
                visibility: accountData.profile.visibility || [],
              }
            } else if (part === "bio") {
              dataPart = { bio: accountData.profile.bio || "" }
            } else if (part === "employment") {
              dataPart = {
                employment: accountData.profile.employment || {
                  role: "",
                  department: "",
                  status: "",
                  industry: {
                    id: 0,
                  },
                  work_setup: [],
                  company: "",
                  company_location: { city: "", country: "", full_address: "" },
                  years_exp: null,
                },
                visibility: accountData.profile.visibility || [],
              }
            } else if (part === "cv") {
              dataPart = { uploaded_cv: accountData.profile.uploaded_cv || "" }
            } else if (part === "socials") {
              dataPart = { socials: accountData.profile.socials || [] }
            }

            // for experience
            if (part === "work_history") {
              dataPart = {
                work_history: accountData.experience.work_history || [],
              }
            } else if (part === "education") {
              dataPart = { education: accountData.experience.education || [] }
            } else if (part === "projects") {
              dataPart = { projects: accountData.experience.projects || [] }
            } else if (part === "skills") {
              dataPart = { skills: accountData.experience.skills || [] }
            } else if (part === "certifications") {
              dataPart = {
                certifications: accountData.experience.certifications || [],
              }
            } else if (part === "interests") {
              dataPart = { interests: accountData.experience.interests || [] }
            }

            if (part === "details" || part === "bio" || part === "employment" || part === "cv" || part === "socials")
              this.customerDetails.profile = {
                ...this.customerDetails.profile,
                ...dataPart,
              }
            else
              this.customerDetails.experience = {
                ...this.customerDetails.experience,
                ...dataPart,
              }
          }
          resolve(true)
          return true
        } catch (err: any) {
          if (err.response.data.exception !== "User data not found") {
            const toast = await toastController.create({
              message: err.response?.data?.message || "Something went wrong",
              duration: 3000,
              position: "top",
              color: "danger",
            })
            await toast.present()
          }
          reject()
        }
      })
    },

    /**
     *
     * @param token - user_id (string)
     *
     * retrieve user talent profile by token
     */
    async retrieveTalentProfile(token: string) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/details?token=${token}`, undefined)
        const parsed: any = jwt.parseToken(response.data)
        if (parsed) {
          const accountData = JSON.parse(parsed!.payloadObj!.sub)
          this.userTalentProfile = { ...this.userTalentProfile, ...accountData.profile }
          this.worksona = ""
          this.worksonaDetails = {
            dominant: [],
            dormant: [],
            maxValue: 0,
          }
          return true
        }
        return false
      } catch (err: any) {
        return false
      }
    },
    /**
     * get worksona
     *
     * @param mode - "me" | "public" (string)
     */
    getWorksonaResult(mode = "me") {
      const worksonaResult = JSON.parse(JSON.stringify(mode == "me" ? this.customerDetails.profile.worksona : this.userTalentProfile.worksona))
      delete worksonaResult.last_take
      const data = values(worksonaResult)
      const maxValue = max(data)
      if (maxValue && maxValue > 0) {
        this.worksonaDetails.maxValue = maxValue
        const keysMap = map(mode == "me" ? this.customerDetails.profile.worksona : this.userTalentProfile.worksona, (val, key) => (val === maxValue ? key : false))
        const result = first(filter(keysMap, (val) => val !== false))
        this.worksona = result as string
        const sortData = sortBy(data)
        const leastScore = uniq(take(sortData, 3))
        const topScore = uniq(takeRight(sortData, 3))
        const leastMap = [] as any[]
        const topMap = [] as any[]
        each(mode == "me" ? this.customerDetails.profile.worksona : this.userTalentProfile.worksona, (val, key) => {
          each(leastScore, (score) => {
            if (val === score) leastMap.push({ name: key, score })
          })

          each(topScore, (score) => {
            if (val === score) topMap.push({ name: key, score })
          })
        })
        this.worksonaDetails.dormant = reverse(take(map(sortBy(leastMap, "score"), "name"), 3)) as string[]
        map(mode == "me" ? this.customerDetails.profile.worksona : this.userTalentProfile.worksona, (val, key) => (includes(topScore, val) ? key : false))
        this.worksonaDetails.dominant = reverse(take(map(sortBy(topMap, "score"), "name"), 3)) as string[]
      }
    },
  },
})
