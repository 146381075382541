import { defineStore } from "pinia"
import jwt from "@/utils/jwt"
import { useTokenStore } from "./token"
import { map } from "lodash"
import axios from "axios"
import router from "@/router"
import { toastController } from "@ionic/core"
import { escape } from "lodash"

const root = "company"

interface Salary {
  currency: string
  maximum: number
  minimum: number
}
interface Condition {
  employment_type: string
  work_setup: string
}

interface Application {
  deadline: string
  min_exp: number
  vacancy: number
}
interface JobDetails {
  type: string
  salary?: Salary
  condition: Condition
  department: string[]
  application: Application
  display_link: string
  roles_responsibility: string
  created: string
  job_id: string
  tech_stack: string[]
  status: string
  title: string
  slug: string
  updated: string
  apply_link: string
  company_id: string
  requirements: string
  description: string
  urgent: boolean
  follows: string[]
}

interface Industry {
  id: number
  name: string
}

interface Coordinate {
  lat: number
  long: number
}
interface Location {
  city: string
  coordinates: Coordinate
  country: string
  full_address: string
  postal_code: string
  state: string
  street: string
  area?: {
    northeast: Coordinate
    southwest: Coordinate
  }
}
interface CoreValue {
  description: string
  image: string
  label: string
}
interface Social {
  media_type: string
  link: string
}

interface Metadata {
  title: string
  description: string
}

interface IdDateObject {
  id: string
  date_published: string
}

interface NameScoreObject {
  name: string
  score: number
}
interface BrandScoreObject {
  brand_scores: NameScoreObject[]
  date_published: string
}

interface InsightDetails {
  period?: string
  population?: number
  departments?: string[]
  tags?: string[]
  strengths?: string[]
  weaknesses?: string[]
}

interface Insight {
  ebm?: BrandScoreObject
  ebm_details?: InsightDetails
  ebm_details_visibility?: string[]
  ebp?: IdDateObject
  ebp_details?: InsightDetails
  ebp_details_visibility?: string[]
  lebp?: BrandScoreObject
  lebp_details?: InsightDetails
  lebp_details_visibility?: string[]
  persona?: IdDateObject
  worksona?: IdDateObject
}
interface Gallery {
  image: string
  label: string
}

interface Person {
  image: string
  name: string
  position: string
  testimonial: string
}

interface FAQ {
  question: string
  answer: string
}
interface BAT {
  id: string
  name: string
}

interface Leadership {
  name: string
  label: string
  image: string
}

interface Story {
  title: string
  description: string
  link: string
}

interface CompanyDetails {
  company_id: string
  year_founded: number
  industry: Industry
  url: string
  location: Location
  covid_response: string
  draft: string
  mission: string
  vision: string
  employee_tags: string[]
  number_of_employees: string
  name: string
  core_value: CoreValue[]
  slug: string
  published: boolean
  average_age: string
  work_setup: string
  company_type: string
  logo: string
  departments: string[]
  tagline: string
  about: string
  socials: Array<Social>
  insight: Insight
  job: JobDetails[]
  brand_color: string
  gallery: Gallery[]
  story: Array<Story>
  testimonial: Array<Person>
  technologies: Array<BAT>
  faq: Array<FAQ>
  benefits: Array<BAT>
  amenities: Array<BAT>
  leadership: Array<Leadership>
  follows: Array<string>
  subscription: string
  cover_photo?: string
  metadata: Metadata
}

export const useCompanyStore = defineStore("company", {
  state: () => ({
    allJobs: [] as any,
    companyDetails: <CompanyDetails>{
      company_id: "",
      year_founded: 0,
      industry: {
        id: -1,
        name: "",
      },
      url: "",
      location: {
        city: "",
        coordinates: {
          lat: 0,
          long: 0,
        },
        country: "",
        full_address: "",
        postal_code: "",
        state: "",
        street: "",
      },
      covid_response: "",
      draft: "",
      mission: "",
      vision: "",
      employee_tags: [],
      number_of_employees: "",
      name: "",
      core_value: [],
      slug: "",
      published: false,
      average_age: "",
      work_setup: "",
      company_type: "",
      logo: "",
      departments: [],
      tagline: "",
      about: "",
      socials: [],
      insight: {},
      job: [],
      brand_color: "",
      gallery: [],
      story: [],
      testimonial: [],
      technologies: [],
      faq: [],
      benefits: [],
      amenities: [],
      leadership: [],
      follows: [],
      subscription: "",
      cover_photo: "",
      metadata: {
        title: "",
        description: "",
      },
    },
    jobDetails: <JobDetails>{
      type: "",
      salary: {
        currency: "",
        maximum: 0,
        minimum: 0,
      },
      condition: {
        employment_type: "",
        work_setup: "",
      },
      department: [],
      application: {
        deadline: "",
        min_exp: 0,
        vacancy: 0,
      },
      display_link: "",
      roles_responsibility: "",
      created: "",
      job_id: "",
      tech_stack: [],
      status: "",
      title: "",
      slug: "",
      updated: "",
      apply_link: "",
      company_id: "",
      requirements: "",
      description: "",
      urgent: false,
      follows: [] as string[],
    },
    companyColor: "",
    companies: [] as any,
    searchResult: [] as any,
    filters: {
      industries: [] as string[],
      keyword: "",
      keywords: "",
      industry: "",
      worksetup: "",
      worktype: "",
      companies: {} as any,
      techstack: "",
      benefits: "",
      amenities: "",
      companysize: "",
      companytype: "",
      ebp: "",
      sort: "recent",
    },
    filterParams: {
      keywords: "", //job
      keyword: "", //company
      companies: "", //job
      techstack: "", //job
      industry: "",
      worksetup: "",
      companytype: "",
      companysize: "",
      location: "",
      benefits: "",
      amenities: "",
      ebp: "",
      columns: "",
      sort: "recent",
      page: 1,
      limit: 10,
    },
    isLast: false,
    isNewState: false,
  }),

  actions: {
    /**
     *
     * @param params  - An object containing filter job board filter values.
     * @param slug - if specific company
     *
     * Rerieves all the job posts, including workbean-created jobs and third-party
     * integrations. Retrieved results are based on the passed filters.
     */
    async retrieveAllJobs(params: object, slug = "") {
      try {
        const header = <any>JSON.parse(JSON.stringify(useTokenStore().authorizationHeader))
        header.params = params
        this.isLast = true
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/job-list${slug ? `/${slug}` : ""}`, header)
        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          const data = JSON.parse((parsed.payloadObj as any).sub)
          if (this.isNewState) this.allJobs = data
          else this.allJobs = [...this.allJobs, ...data]
          this.isLast = data.length ? false : true
          delete header.params
        }
      } catch (error: any) {
        const toast = await toastController.create({
          message: error.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     *
     * @param params - an object to filters companies
     * @param type - string discover | search
     * retrieve all companies data
     */
    async retrieveAllCompanies(params: object, type = "discover") {
      try {
        this.isLast = true
        const header = <any>JSON.parse(JSON.stringify(useTokenStore().authorizationHeader))
        header.params = Object.assign({}, params)
        header.params.keyword = escape(header.params.keyword)
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/companies`, header)
        delete header.params

        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          const data = JSON.parse((parsed.payloadObj as any).sub)
          if (type === "search") {
            if (this.isNewState) this.searchResult = data
            else this.searchResult = [...this.searchResult, ...data]
          } else {
            if (this.isNewState) this.companies = data
            else this.companies = [...this.companies, ...data]
          }
          this.isLast = data.length ? false : true
        }
        return true
      } catch (err: any) {
        const toast = await toastController.create({
          message: err.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     *
     * @param slug - Company slug
     *
     * Retrieves company details
     */
    async retrieveCompanyDetails(slug: string) {
      try {
        const config = <any>useTokenStore().authorizationHeader
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/${slug}`, config)

        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          const data = JSON.parse((parsed.payloadObj as any).sub)
          this.companyDetails = data
          this.companyColor = data.brand_color
        }
      } catch (error: any) {
        if (error?.response?.data?.exception === "Company not found") await router.replace({ path: "/not-found" })
        else {
          const toast = await toastController.create({
            message: error.response?.data?.message || "Something went wrong",
            duration: 3000,
            position: "top",
            color: "danger",
          })
          await toast.present()
        }
      }
    },

    /**
     *
     * @param slug - Job post slug
     *
     * Retrieves a specific job post details
     */
    async retrieveJobPost(slug: string) {
      try {
        const config = <any>useTokenStore().authorizationHeader
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/job-post/${slug}?columns=&status=`, config)

        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          const data = JSON.parse((parsed.payloadObj as any).sub)
          this.jobDetails = data
        }
      } catch (error: any) {
        const toast = await toastController.create({
          message: error.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     * request to apply job based on slug
     * @param slug - job slug
     */
    async applyJob(slug: string) {
      try {
        const config = <any>useTokenStore().authorizationHeader
        await axios.post(`${process.env.VUE_APP_WB_API}/${root}/apply-now/${slug}`, null, config)
        const toast = await toastController.create({
          message: "Application Submitted",
          duration: 3000,
          position: "top",
          color: "success",
        })
        await toast.present()
      } catch (error: any) {
        const toast = await toastController.create({
          message: error.response?.data?.message || "Unable to submit your application. Plese try again or contact our support",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     * get apply email
     * @param slug - job slug
     */
    async getApplyEmail(slug: string) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_WB_API}/${root}/apply-now/${slug}`)
        const parsed = jwt.parseToken(response.data)
        if (parsed) {
          const ownerEmail = JSON.parse((parsed.payloadObj as any).sub)
          return ownerEmail
        }
      } catch (error: any) {
        const toast = await toastController.create({
          message: error.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
        return ""
      }
    },

    /**
     *
     * @param question - question to be sent
     * @param slug - company slug
     * @param nbf -  number used for jwt
     *
     * Sends the question to specified company
     */
    async sendQuestion(question: string, slug: string, nbf: number) {
      try {
        const config = <any>useTokenStore().authorizationHeader
        const tokenizedPayload = jwt.generateToken(question, nbf)
        await axios.post(`${process.env.VUE_APP_WB_API}/${root}/ask-question/${slug}`, tokenizedPayload, config)
        const toast = await toastController.create({
          message: "Question successfully submitted",
          duration: 3000,
          position: "top",
          color: "success",
        })
        await toast.present()
        return true
      } catch (error: any) {
        const toast = await toastController.create({
          message: error.response?.data?.message || "Unable to send question. Please try again.",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },

    /**
     * follow job post/company
     * @param type - type to follow
     * @param token - job/company slug
     */
    async follow(type: "job" | "company", token: string) {
      try {
        const header = <any>JSON.parse(JSON.stringify(useTokenStore().authorizationHeader))
        header.params = { token }
        await axios.post(`${process.env.VUE_APP_WB_API}/${root}/follow/${type}`, null, header)
        const toast = await toastController.create({
          message: type === "job" ? "You are following this job" : "You are now following this company",
          duration: 3000,
          position: "top",
          color: "success",
        })
        await toast.present()
        delete header.params
        return true
      } catch (error: any) {
        const toast = await toastController.create({
          message: error.response?.data?.message || "Something went wrong",
          duration: 3000,
          position: "top",
          color: "danger",
        })
        await toast.present()
      }
    },
    /**
     * create params for url
     * @param isNewState if the retrieving data will reset
     */
    generateFilter(isNewState = false) {
      // setting industries
      if (this.filters.industries.length) {
        this.filterParams.industry = map(this.filters.industries, "id").join()
      } else this.filterParams.industry = ""
      this.filterParams.keyword = this.filters.keyword
      this.filterParams.keywords = this.filters.keywords
      this.filterParams.sort = this.filters.sort
      if (this.filters.companysize.length) this.filterParams.companysize = map(this.filters.companysize).join("|")
      else this.filters.companysize = ""
      if (this.filters.companytype.length) this.filterParams.companytype = map(this.filters.companytype).join()
      else this.filters.companytype = ""
      if (this.filters.worksetup.length) this.filterParams.worksetup = map(this.filters.worksetup).join()
      else this.filters.worksetup = ""
      if (this.filters.amenities.length) this.filterParams.amenities = map(this.filters.amenities).join()
      else this.filters.amenities = ""
      if (this.filters.benefits.length) this.filterParams.benefits = map(this.filters.benefits).join()
      else this.filters.benefits = ""
      if (this.filters.ebp.length) this.filterParams.ebp = map(this.filters.ebp).join()
      else this.filters.ebp = ""
      if (this.filters.companies?.slug) this.filterParams.companies = this.filters.companies?.slug
      else this.filterParams.companies = ""
      this.isNewState = isNewState
    },
  },
})
