import { formatDistanceToNow, parse, formatISO } from "date-fns"

/**
 *
 * @param date string date from backend
 * @param dateformat string date format
 * @returns UTC local time equivalent of the param date
 *
 * Converts the string into date format and parses it to localtime
 */
const convertToLocalUTC = (date: string, dateformat?: string) => {
  const parsedDate = formatISO(parse(date, dateformat || "dd/MM/yy HH:mm:ss", new Date()))
  const localtime = new Date(parsedDate.slice(0, parsedDate.indexOf("+")) + "Z")

  return localtime
}

export default {
  // Computes the distance between a specified date and the current date and time
  computeDistanceToNow: (date: string) => {
    return formatDistanceToNow(convertToLocalUTC(date), { addSuffix: true })
  },
  convertToLocalUTC,
}
