import { createApp } from "vue"
import { createPinia } from "pinia"
import { createGtm } from "@gtm-support/vue-gtm"
import App from "./App.vue"
import router from "./router"
import VueSocialSharing from "vue-social-sharing"
import { initApplication } from "./utils/analytics"

import { IonicVue } from "@ionic/vue"

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css"
import "@ionic/vue/css/structure.css"
import "@ionic/vue/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css"
import "@ionic/vue/css/float-elements.css"
import "@ionic/vue/css/text-alignment.css"
import "@ionic/vue/css/text-transformation.css"
import "@ionic/vue/css/flex-utils.css"
import "@ionic/vue/css/display.css"

/* Theme variables */
import "./theme/variables.css"
import "@/assets/icons/style.css"
import "./assets/style/index.css"
import "./assets/icons/style.css"
import "./assets/style/custom.scss"

initApplication()

const app = createApp(App)
  .use(createPinia())
  .use(IonicVue, {
    rippleEffect: false,
    mode: "md",
  })
  .use(router)
  .use(VueSocialSharing)
  .use(
    createGtm({
      id: "GTM-N57ZSCK",
    })
  )

router.isReady().then(() => {
  app.mount("#app")
})
