import { Amplify, Analytics, AWSKinesisProvider } from "aws-amplify"
import awsconfig from "../aws-exports"
import { useAccountStore } from "@/store/account"

interface DataEvent {
  event: string
  attributes?: object
  account?: {
    email: string
    type: string
  }
  timestamp?: Date
}

const initApplication = () => {
  Amplify.configure(awsconfig)
  Analytics.addPluggable(new AWSKinesisProvider())

  Analytics.autoTrack("pageView", {
    enable: true,
    type: "SPA",
    provider: "AWSKinesis",
    eventName: "pageView",
    eventParameters: {
      streamName: process.env.VUE_APP_STREAM_NAME,
      partitionKey: "workbean-mobile",
    },
  })
  Analytics.autoTrack("event", {
    enable: true,
    provider: "AWSKinesis",
    eventParameters: {
      streamName: process.env.VUE_APP_STREAM_NAME,
      partitionKey: "workbean-mobile",
    },
  })

  Analytics.configure({
    AWSKinesis: {
      region: awsconfig.aws_project_region,
    },
  })
}

const record = (data: DataEvent, opt = { immediate: true }) => {
  const account = useAccountStore()
  if (!data["timestamp"]) {
    data["timestamp"] = new Date()
  }
  if (!data["account"] && account.email !== "") {
    data["account"] = {
      email: account.email,
      type: account.type,
    }
  }
  Analytics.record(
    {
      data: data,
      partitionKey: "workbean-mobile",
      streamName: process.env.VUE_APP_STREAM_NAME || "",
      ...opt,
    },
    "AWSKinesis"
  )
  Analytics.record(
    {
      data: data,
      partitionKey: "workbean",
      streamName: process.env.VUE_APP_STREAM_NAME || "",
      ...opt,
    },
    "AWSKinesis"
  )
}

export { initApplication, record }
