import { defineStore } from "pinia"

interface MetaParams {
  title: string
  description: string
  image: string
}

export const useMetadataStore = defineStore("metadata", {
  state: () => ({
    details: {
      title: "Workbean",
      description: "Discover Southeast Asian's largest directory of company culture",
      robots: "index, follow",
      og: {
        title: "Workbean",
        type: "website",
        description: "Discover Southeast Asian's largest directory of company culture",
        image: "https://assets.workbean.co/logo/v4/workbean-meta-banner.png",
        url: window.location.href,
        site_name: "Workbean",
        first_name: "",
        last_name: "",
        author: "",
        published_time: "",
        modified_time: "",
        section: "",
        canonical: window.location.href,
      },
      twitter: {
        title: "Workbean",
        description: "Discover Southeast Asian's largest directory of company culture",
        image: "https://assets.workbean.co/logo/v4/workbean-meta-banner.png",
        site: window.location.href,
        creator: "Workbean",
      },
    },
    domain: "",
  }),
  actions: {
    setMetaDetails(param: MetaParams) {
      this.details.title = param.title
      this.details.description = param.description
      this.details.og = { ...this.details.og, ...param }
      this.details.twitter = { ...this.details.twitter, ...param }
    },
  },
})
