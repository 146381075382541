import { useCompanyStore } from "@/store/company"
import { useAccountStore } from "@/store/account"
import { computed } from "vue"
import { toastController } from "@ionic/core"

export default {
  /**
   *
   * @param slug job/company slug
   * @param type follow type either job or company
   *
   * Calls on company store's follow api.
   */

  followJobOrCompany: async (type: "company" | "job", slug: string, gtm?: any) => {
    const companyStore = useCompanyStore()

    await companyStore.follow(type, slug)
    if (type === "company") companyStore.retrieveCompanyDetails(slug)
    if (gtm?.enabled()) {
      gtm.trackEvent({
        event: `${type}_event`,
        label: `${type}_post`,
        value: `${type}-value`,
      })
    }
  },

  /**
   *
   * @param type - job or company
   * @param jobFollows - Array of account IDs following/liking the job
   * @returns true if following, false if not
   */
  status: (type: "job" | "company", jobFollows?: Array<string>, companyFollows?: Array<string>) => {
    const companyStore = useCompanyStore()
    const accountStore = useAccountStore()

    const following = computed(() => {
      // check follow for company cards
      if (companyFollows) {
        return companyFollows.includes(accountStore.account_id)
      }
      // check follow for company page
      else if (type === "company" && companyStore.companyDetails.follows && companyStore.companyDetails.follows.includes(accountStore.account_id)) return true
      // check follow for both job card and job page
      else if (type === "job") {
        const follows: string[] = jobFollows || []
        return follows.includes(useAccountStore().account_id) || false
      }
      return false
    })
    return following.value
  },

  // Alert to remind user that they are already following/liking
  alreadyFollowing: async () => {
    const toast = await toastController.create({
      message: "Already followed",
      duration: 3000,
      position: "top",
      color: "warning",
    })
    await toast.present()
  },
}
