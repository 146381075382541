import { b64utoutf8, KJUR } from "jsrsasign"
import { zonedTimeToUtc } from "date-fns-tz"
import { getUnixTime, sub } from "date-fns"

interface Payload {
  [key: string]: any
}

const isValidity = (token: string) => {
  return KJUR.jws.JWS.verifyJWT(token, process.env.VUE_APP_SECRET_KEY || "", {
    alg: ["HS256"],
  })
}

export default {
  /**
   * Returns the decoded header and payload of a JWT
   *
   * @param token - JWT of type string
   * @returns objects for header and payload
   */
  parseToken: (token: string) => {
    // validate that the token is from WB server
    let isValid = isValidity(token)
    let numberTimes = 1
    do {
      isValid = isValidity(token)
      numberTimes += 1
      if (numberTimes > 10000) isValid = !isValid
    } while (!isValid)
    if (numberTimes > 10000) return false

    // parse headers and payload
    const headerObj = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(token.split(".")[0]))
    const payloadObj = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(token.split(".")[1]))

    return { headerObj, payloadObj }
  },

  /**
   * Returns the JWT encoded payload
   *
   * @param payload - expected to be an object
   * @returns JWT
   */
  generateToken: (body: Payload | string, date: number) => {
    // initialize token header
    const oHeader = { alg: "HS256", typ: "JWT" }

    // initialize payload timings and sub
    const tNow = KJUR.jws.IntDate.get("now")
    const tEnd = KJUR.jws.IntDate.get("now + 1day")
    const payload = {
      sub: typeof body === "string" ? body : JSON.stringify(body),
      nbf: date,
      iat: date,
      exp: tEnd,
      iss: process.env.VUE_APP_TOKEN_ISS,
      aud: process.env.VUE_APP_TOKEN_AUD,
    }
    // stringify payload and header
    const sHeader = JSON.stringify(oHeader)
    const sPayload = JSON.stringify(payload)

    // generate token
    const sJWT = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, process.env.VUE_APP_SECRET_KEY || "")

    return sJWT
  },

  generateNBF: () => {
    const yesterday = sub(new Date(), { hours: 1 })
    const utcYesterday = zonedTimeToUtc(yesterday, Intl.DateTimeFormat().resolvedOptions().timeZone)
    return KJUR.jws.IntDate.get(`${getUnixTime(utcYesterday)}`)
  },
}
