import { defineStore } from "pinia"
interface headerInput {
  headers: object
  params?: object
}

export const useTokenStore = defineStore("token", {
  state: () => ({
    token: "",
  }),
  getters: {
    activeToken: () => localStorage.getItem("WBSAASAPP_TK"), // Gets the current user token
    /**
     *
     * @returns authorization header config based on the logged in user
     */
    authorizationHeader: () => {
      const token = localStorage.getItem("WBSAASAPP_TK")
      const config = <headerInput>{
        headers: {
          "Content-Type": "text/html; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      }
      return config
    },
    /**
     *
     * @returns authorization header config based on the logged in user
     */
    normalHeader: () => {
      const config = <headerInput>{
        headers: {
          "Content-Type": "text/html; charset=utf-8",
        },
      }
      return config
    },
  },
  actions: {
    /**
     *
     * @param newValue string value of the user token
     *
     * takes the newValue and stores token to localStorage with an item name of 'WBSAASAPP_TK'
     */
    setToken(newValue: string) {
      localStorage.setItem("WBSAASAPP_TK", newValue)
    },
  },
})
